import React, { useEffect } from "react";
import ErrorWrapper from "./ErrorWrapper";
import { useErrorContext } from '../../../error';

function Error() {
  const { error, setErrorState, clearErrorState } = useErrorContext();

  useEffect(() => {
    // setErrorState(true);
  }, [setErrorState]);

  return (
    <>
    {error ? (
      <ErrorWrapper />
      ) : (
        <></>
      )
      }
    </>
  );
}

export default Error;
