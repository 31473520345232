import React, { useState, useEffect } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PackageDetailsWrap from "./PackageDetailsWrap";
import { getPackageById } from "../../../services/packageServices";
import { useParams } from 'react-router-dom';
import { useErrorContext } from '../../../error';
import { decryptData } from "../../../utils/secureEncoder";
import Error from "../../pages/error/Error";

function PackageDetails() {
  const { error, setErrorState, clearErrorState } = useErrorContext();
  const { encodedId } = useParams();
  const [decodedId, setDecodedId] = useState(null);
  const [dataStore, setDataStore] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (encodedId) {
      try {
        const decoded = decryptData(encodedId);
        if (!decoded) {
          throw new Error("Decoded ID is invalid");
        }
        setDecodedId(decoded);
      } catch (error) {
        setErrorState(true);
      }
    } else {
      setLoading(false)
      setErrorState(true);
    }
  }, [encodedId]);

  useEffect(() => {
    if (decodedId) {
      setLoading(true);
      clearErrorState(); 
      getPackageById(decodedId)
        .subscribe(
          (response) => {
            setDataStore(response);
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            setErrorState(true);
          }
        );
    }
  }, [decodedId]);

  if (error) {
    <></>
  }
  
  return (
    <>
      <Breadcrumb name="Package Details" dataStore={dataStore} loading={loading} type={"details"}/>
      <PackageDetailsWrap dataStore={dataStore} loading={loading} />
    </>
  );
}

export default PackageDetails;
