import React from "react";

function Loading() {
  return (
    <>
      <div className="preloader">
            <div className="loader">
                <img src="https://www.operadora.tur.br/assets/frontend/images/logo-operadora.png" className="logo" />
            </div>
        </div>
    </>
  );
}

export default Loading;
