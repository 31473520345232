import axios from 'axios';
import { Observable } from 'rxjs';
import config from '../config';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

function fetchWithRetry(url, retries = MAX_RETRIES) {
  return axios.get(url).catch((error) => {
    if (
      retries > 0 && 
      error.response && 
      (error.response.status === 502 || error.response.status === 500)
    ) {
      return new Promise((resolve) => setTimeout(resolve, RETRY_DELAY)).then(() =>
        fetchWithRetry(url, retries - 1)
      );
    }
    throw error;
  });
}

export function createPackage(datastore) {
  return new Observable((subscriber) => {
    fetchWithRetry('/api/createPackage')
      .then((dataStore) => {
        subscriber.next(dataStore);
        subscriber.complete();
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        } else {
          subscriber.error(error);
        }
      });
  });
}

export function getPackageById(encodedId) {
  return new Observable((subscriber) => {
    fetchWithRetry(`${config.apiUrl}/TravelPackage/package/${encodedId}`)
      .then(response => {
        subscriber.next(response.data);
        subscriber.complete();
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        } else {
          subscriber.error(error);
        }
      });
  });
}

export function getPackageByType(type, pageSize, pageNumber) {
  return new Observable((subscriber) => {
    const url = `${config.apiUrl}/TravelPackage/type/${type}?PageSize=${pageSize}&PageNumber=${pageNumber}`;
    
    fetchWithRetry(url)
      .then((response) => {
        if (response.status === 200) {
          subscriber.next(response.data);
          subscriber.complete();
        } else if (response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        } else {
          subscriber.error(error);
        }
      });
  });
}

export function getAllContinents() {
  return new Observable((subscriber) => {
    fetchWithRetry(`${config.apiUrl}/Continent/GetAll`)
      .then((response) => {
        if (response.status === 200) {
          subscriber.next(response.data);
          subscriber.complete();
        } else if (response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        } else {
          subscriber.error(error);
        }
      });
  });
}

export function search(continent, destination, type, startDate, endDate, pageSize, pageNumber) {
  let url = `${config.apiUrl}/TravelPackage/search?`;

  if (continent !== null) url += `Continent=${continent}&`;
  if (destination !== null) url += `Destination=${destination}&`;
  if (type !== null) url += `CategoryId=${type}&`;
  if (startDate !== null) url += `startDate=${startDate}&`;
  if (endDate !== null) url += `endDate=${endDate}&`;
  if (pageSize !== null) url += `PageSize=${pageSize}&`;
  if (pageNumber !== null) url += `PageNumber=${pageNumber}`;

  return new Observable((subscriber) => {
    fetchWithRetry(url)
      .then((response) => {
        if (response.status === 200) {
          subscriber.next(response.data);
          subscriber.complete();
        } else if (response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          subscriber.error({ redirectTo: '/error' });
        } else {
          subscriber.error(error);
        }
      });
  });
}
