// utils/secureEncoder.js
import CryptoJS from 'crypto-js';

// Certifique-se de que a chave tem 32 caracteres
const SECRET_KEY = 'aBcD1eFgH2iJkL3mNoPq4RsTuV5wXy6Z';

// Função para codificar uma string Base64 para uma versão segura para URL
const base64UrlEncode = (str) => {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

// Função para decodificar uma string Base64 segura para URL de volta para Base64
const base64UrlDecode = (str) => {
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  while (str.length % 4) {
    str += '=';
  }
  return str;
};

export const encryptData = (data) => {
  try {
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, { 
      mode: CryptoJS.mode.ECB, 
      padding: CryptoJS.pad.Pkcs7 
    }).toString();
    
    const base64UrlEncrypted = base64UrlEncode(encrypted);
    return base64UrlEncrypted;
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};

export const decryptData = (ciphertext) => {
  try {
    const base64Encrypted = base64UrlDecode(ciphertext);

    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const bytes = CryptoJS.AES.decrypt(base64Encrypted, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (error) {
    console.error('Decryption error:', error);
    return 'Decryption failed.';
  }
};