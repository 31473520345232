import React, { useEffect, useState } from "react";
import HomeHeroArea from "./HomeHeroArea";
import MoreUpdate from "./MoreUpdate";
import PackageArea from "./PackageArea";
import SearchBar from "./SearchBar";
import TopDestination from "./TopDestination";
import { getPackageByType, getAllContinents } from "../../../services/packageServices";

function HomePage() {
  const [loading, setLoading] = useState(true);
  const [groupsAndBlocks, setGroupsAndBlocks] = useState(null);
  const [regularDepartures, setRegulardDepartures] = useState(null);
  const [continents, setContinents] = useState(null);
  const IdGroupsAndBlocks = 1;
  const IdRegularDepartures = 2;
  const pageSizeGroupsAndBlocks = 6;
  const pageSizeRegularDepartures = 6;
  const pageNumberGroupsAndBlocks = 1;
  const pageNumberRegularDepartures = 1;

  useEffect(() => {
    getPackageByType(IdGroupsAndBlocks, pageSizeGroupsAndBlocks, pageNumberGroupsAndBlocks)
      .subscribe(
        (response) => {
          setGroupsAndBlocks(response);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching packages:", error);
          setLoading(false);
        }
      );

  }, []);

  useEffect(() => {
    getPackageByType(IdRegularDepartures, pageSizeRegularDepartures, pageNumberRegularDepartures)
      .subscribe(
        (response) => {
          setRegulardDepartures(response)
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching packages:", error);
          setLoading(false);
        }
      );
  }, []);

  useEffect(() => {
  }, [groupsAndBlocks]);

  useEffect(() => {
  }, [regularDepartures]);

  useEffect(() => {
    getAllContinents()
    .subscribe((response) => {
      setContinents(response);
    })
  }, []);

  return (
    <>
      <HomeHeroArea />
      <SearchBar/>
      <PackageArea dataStore={groupsAndBlocks} type={IdGroupsAndBlocks} loading={loading}/>
      <PackageArea dataStore={regularDepartures} type={IdRegularDepartures} loading={loading}/>
      <TopDestination dataStore={continents}/>
      <MoreUpdate/>
    </>
  );
}

export default HomePage;
