import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./components/App";
import About from "./components/pages/about/About";
import Contact from "./components/pages/contact/Contact";
import DestinationDetails from "./components/pages/destinationDetails/DestinationDetails";
import Destinations from "./components/pages/destinetion/Destinatination";
import Error from "./components/pages/error/Error";
import PackageDetails from "./components/pages/packageDetails/PackageDetails";
import PackageGrid from "./components/pages/packageGrid/PackageGrid";
import PackageSidebar from "./components/pages/packageSidebar/PackageSIdebar";
import PackageStandard from "./components/pages/packageStandard/PackageStandard";
import SimpleReactLightbox from "simple-react-lightbox";
import { ErrorProvider } from "./error";
import "./index.css";
import Home from './components/layout/Home';

function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/" component={Home} />
          
          <Layout>
            <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
            <Route exact path={`${process.env.PUBLIC_URL}/destination`} component={Destinations} />
            <Route exact path={`${process.env.PUBLIC_URL}/destination-details`} component={DestinationDetails} />
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/package/search`} component={PackageSidebar} />
              <Route exact path={`${process.env.PUBLIC_URL}/package/:encodedId`} component={PackageGrid} />
            </Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/package-sidebar`} component={PackageSidebar} />
            <Route exact path={`${process.env.PUBLIC_URL}/package-standard`} component={PackageStandard} />
            <Route exact path={`${process.env.PUBLIC_URL}/package-details/:encodedId`} component={PackageDetails} />
            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
            <Route path="/:catchAll?" component={Error} />
          </Layout>

          <Route path="*" component={Error} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Root;
ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <ErrorProvider>
        <Root />
      </ErrorProvider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
