import React from "react";
import { Link } from "react-router-dom";
import { encryptData } from "../../utils/secureEncoder";

function Pagination({ data }) {
  if (!data) {
    return <></>;
  }

  const isMobile = window.innerWidth <= 768;
  const maxPageSize = isMobile ? 2 : 8;

  const formattedCurrentPage = data.pageNumber.toString().padStart(2, "0");

  const totalPages = data.totalPages;
  const currentPage = parseInt(formattedCurrentPage);

  let startPage = Math.max(1, currentPage - Math.floor(maxPageSize / 2));
  let endPage = Math.min(totalPages, startPage + maxPageSize - 1);

  if (endPage - startPage + 1 < maxPageSize) {
    endPage = Math.min(totalPages, startPage + maxPageSize - 1);
    startPage = Math.max(1, endPage - maxPageSize + 1);
  }

  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) =>
    (startPage + index).toString().padStart(2, "0")
  );

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <nav>
            <ul className="pagination pagination-style-one justify-content-center pt-50">
              <li
                className={`page-item page-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <Link
                  to={
                    currentPage > 1
                      ? (data.urlParams !== null && data.urlParams !== undefined) 
                      ? `?${data.urlParams}PageNumber=${currentPage - 1}&PageSize=${data.pageSize}`
                      : `?PageNumber=${currentPage - 1}&PageSize=${data.pageSize}`
                      : "#"
                  }
                  onClick={scrollTop}
                  className={`page-link ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <i className="bi bi-chevron-double-left" />
                </Link>
              </li>
              {startPage > 1 && (
                <>
                  <li
                    className={`page-item ${
                      formattedCurrentPage === "01" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={(data.urlParams !== null && data.urlParams !== undefined) 
                        ? `${encryptData(data.type)}?${data.urlParams}PageNumber=01&PageSize=${data.pageSize}`
                        : `${encryptData(data.type)}?PageNumber=01&PageSize=${data.pageSize}`}
                      onClick={scrollTop}
                      className="page-link"
                    >
                      01
                    </Link>
                  </li>
                  {startPage > 2 && (
                    <li className="page-item disabled">
                      <span className="page-link">...</span>
                    </li>
                  )}
                </>
              )}
              {pageNumbers.map((pageNumber, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    formattedCurrentPage === pageNumber ? "active" : ""
                  }`}
                >
                  <Link
                    to={(data.urlParams !== null && data.urlParams !== undefined) 
                      ? `?${data.urlParams}PageNumber=${pageNumber}&PageSize=${data.pageSize}`
                      : `?PageNumber=${pageNumber}&PageSize=${data.pageSize}`}
                    onClick={scrollTop}
                    className="page-link"
                  >
                    {pageNumber}
                  </Link>
                </li>
              ))}
              {endPage < totalPages && (
                <>
                  {endPage < totalPages - 1 && (
                    <li className="page-item disabled">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  <li
                    className={`page-item ${
                      formattedCurrentPage === totalPages.toString().padStart(2, "0")
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      to={(data.urlParams !== null && data.urlParams !== undefined)
                        ? `${encryptData(data.type)}?${data.urlParams}PageNumber=${totalPages.toString().padStart(2, "0")}&PageSize=${data.pageSize}`
                        : `${encryptData(data.type)}?PageNumber=${totalPages.toString().padStart(2, "0")}&PageSize=${data.pageSize}`}
                      onClick={scrollTop}
                      className="page-link"
                    >
                      {totalPages.toString().padStart(2, "0")}
                    </Link>
                  </li>
                </>
              )}
              <li
                className={`page-item page-arrow ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <Link
                  to={
                    currentPage < totalPages
                      ? (data.urlParams !== null && data.urlParams !== undefined)
                      ? `?${data.urlParams}PageNumber=${currentPage + 1}&PageSize=${data.pageSize}`
                      : `?PageNumber=${currentPage + 1}&PageSize=${data.pageSize}`
                      : "#"
                  }
                  onClick={scrollTop}
                  className={`page-link ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <i className="bi bi-chevron-double-right" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Pagination;
