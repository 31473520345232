import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Pagination, Autoplay } from 'swiper/modules';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'swiper/swiper-bundle.css';

function HomeHeroArea() {
    const [loading, setLoading] = useState(true); // Estado de loading

    const scrollToSection = (event, id) => {
        event.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToClassSection = (event, className) => {
        event.preventDefault();
        const element = document.querySelector(`.${className}`);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - (window.innerHeight / 2) + (element.clientHeight / 2);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    // Simula o carregamento de dados
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Simula a finalização do carregamento
        }, 2000); // 2 segundos de carregamento

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div className="hero-area hero-style-three">
                <div className="hero-main-wrapper position-relative">
                    <Skeleton height={500} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="hero-area hero-style-three">
                <img src={process.env.PUBLIC_URL + "/images/banner/banner-plane.svg"} className="img-fluid banner-plane" alt='banners-img' />
                <div className="hero-main-wrapper position-relative">
                    <Swiper
                        className="hero-slider-three"
                        modules={[Navigation, EffectFade, Pagination, Autoplay]}
                        slidesPerView={1}
                        speed={1500}
                        parallax={true}
                        loop={true}
                        spaceBetween={0}
                        centeredSlides={true}
                        effect={'fade'}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false, // Continua a reprodução após interação
                        }}
                        navigation={{
                            nextEl: '.hero-next3',
                            prevEl: '.hero-prev3',
                        }}
                        pagination={{
                            el: '.hero-two-pagination',
                            clickable: true,
                            renderBullet: (index, className) => {
                                return `<span class="${className}">${index + 1}</span>`;
                            },
                        }}
                    >
                        <SwiperSlide className="swiper-slide">
                            <div className="slider-bg-2">
                                <div className="container">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-lg-8">
                                            <div className="hero3-content">
                                                <span className="title-top">Operadora Ponto Com</span>
                                                <h1>Desfrute cada momento, aventure-se em novos horizontes.</h1>
                                                <p>Prepare-se para viver aventuras inesquecíveis em paraísos distantes, onde cada momento é uma nova descoberta e cada paisagem um convite à exploração.</p>
                                                <Link to="#searchbar-section" onClick={(e) => scrollToClassSection(e, 'searchbar-section')} className="button-fill-primary banner2-btn">Encontre seu próximo destino</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide">
                            <div className="slider-bg-3">
                                <div className="container">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-lg-8">
                                            <div className="hero3-content">
                                                <span className="title-top">Operadora Ponto Com</span>
                                                <h1>Vivencie cada passo, explore novos destinos.</h1>
                                                <p>Desvende os segredos dos lugares mais fascinantes do planeta e descubra tesouros escondidos que aguardam ser explorados em cada canto.</p>
                                                <Link to="#searchbar-section" onClick={(e) => scrollToClassSection(e, 'searchbar-section')} className="button-fill-primary banner3-btn">Encontre seu próximo destino</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        {/* Adicione os outros SwiperSlides aqui */}
                        
                        <SwiperSlide className="swiper-slide">
                            <div className="slider-bg-4">
                                <div className="container">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-lg-8">
                                            <div className="hero3-content">
                                                <span className="title-top">Operadora Ponto Com</span>
                                                <h1>Curta cada experiência, crie memórias inesquecíveis.</h1>
                                                <p>Deixe-se inspirar por destinos que capturam a imaginação, oferecendo uma fonte inesgotável de beleza e cultura para alimentar a mente e a alma.</p>
                                                <Link to="#searchbar-section" onClick={(e) => scrollToClassSection(e, 'searchbar-section')} className="button-fill-primary banner2-btn">Encontre seu próximo destino</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide">
                            <div className="slider-bg-5">
                                <div className="container">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-lg-8">
                                            <div className="hero3-content">
                                                <span className="title-top">Operadora Ponto Com</span>
                                                <h1>Aprecie cada descoberta, embarque em novas aventuras.</h1>
                                                <p>Viva a magia de destinos que parecem um sonho, onde cada vista é um encanto e cada experiência uma lembrança inesquecível.</p>
                                                <Link to="#searchbar-section" onClick={(e) => scrollToClassSection(e, 'searchbar-section')} className="button-fill-primary banner1-btn">Encontre seu próximo destino</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide">
                            <div className="slider-bg-6">
                                <div className="container">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-lg-8">
                                            <div className="hero3-content">
                                                <span className="title-top">Operadora Ponto Com</span>
                                                <h1>Transforme seu sonho em realidade, viaje sem fronteiras.</h1>
                                                <p>Explore culturas fascinantes e crie laços que perdurarão, enquanto cada aventura se torna uma parte da sua história.</p>
                                                <Link to="#searchbar-section" onClick={(e) => scrollToClassSection(e, 'searchbar-section')} className="button-fill-primary banner1-btn">Encontre seu próximo destino</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="slider-arrows text-center d-lg-flex flex-column d-none gap-5">
                        <div className="hero-prev3" tabIndex={0} role="button" aria-label="Previous slide"> <i className="bi bi-arrow-left" /></div>
                        <div className="hero-next3" tabIndex={0} role="button" aria-label="Next slide"><i className="bi bi-arrow-right" /></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeHeroArea;
