import React from 'react'

function MoreUpdate() {
  return (
    <>
    <div className="more-update-section">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center gy-5">
          <div className="col-lg-6 text-lg-start text-center">
            <div className="more-update-content">
              <h2>Junte-se a nós para mais atualizações.</h2>
              <p>Se inscreva na nossa news e receba nosssas novidades!</p>
            </div>
          </div>
          <div className="col-lg-3 text-lg-start text-start">
            <form
              action="https://tur.us10.list-manage.com/subscribe/post?u=9d629c741f1a5b9885a4e376c&amp;id=957062ecf6&amp;f_id=004169e5f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_self"
              noValidate
            >
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group text-white">
                  <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label>
                  <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
                </div>
                <div className="mc-field-group text-white">
                  <label htmlFor="mce-FNAME">Nome <span className="asterisk">*</span></label>
                  <input type="text" name="FNAME" className="text" id="mce-FNAME" required/>
                </div>
                <div className="mc-field-group text-white">
                  <label htmlFor="mce-LNAME">Sobrenome </label>
                  <input type="text" name="LNAME" className="text" id="mce-LNAME" />
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                  <input type="text" name="b_9d629c741f1a5b9885a4e376c_957062ecf6" tabIndex="-1" value="" />
                </div>
                <div className="clear">
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button button-fill-primary update-btn" value="Join Now" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default MoreUpdate