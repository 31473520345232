import React from "react";
import { Link } from "react-router-dom";

function ContactWrapperArea() {
  return (
    <>
      <div className="contact-wrapper pt-110">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center gy-5">
            <div className="col-lg-6">
              <div className="contatc-intro-figure">
                <img
                  src={process.env.PUBLIC_URL + "/images/banner/contact-bg.png"}
                  alt="ContactBannerIMG"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-info">
                <h3>Contato</h3>
                <ul>
                  <li>
                    <h6>Fale Conosco</h6>
                    <a href="mailto:cida@operadora.tur.br">cida@operadora.tur.br</a>
                    <a href="mailto:nadia@operadora.tur.br">nadia@operadora.tur.br</a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://api.whatsapp.com/send/?phone=5551999738501&text&type=phone_number&app_absent=0"
                    >
                       +55 (51) 99973-8501
                    </a>
                  </li>
                  <li>
                    <h6>Localização</h6>
                    <a href="https://goo.gl/maps/8cLmgUVZ322s9FLA6">
                       Rua Jorge Salis Goulart 85, Jardim São Pedro, Porto Alegre - Brasil
                    </a>
                  </li>
                  <li>
                    <h6>Redes Sociais</h6>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/operadorapontocom"
                    >
                      Instagram: https://www.instagram.com/operadorapontocom
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/operadorapontocom"
                    >
                      Facebook: https://www.facebook.com/operadorapontocom
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactWrapperArea;
