import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Loading from "../common/Loading";
import HomePage from "../pages/Home/HomePage";
import MailchimpScript from '../../utils/MailchimpScript';

function Home() {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  }, []);
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <MailchimpScript />
          <Header />
          <HomePage />
          <Footer className="footer-area" />
        </>
      )}
    </>
  );
}

export default Home;
