import { useEffect } from 'react';

const MailchimpScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mcjs';
    script.innerHTML = `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/9d629c741f1a5b9885a4e376c/cb50704b8d1c4e4a86376c155.js");`;
    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById('mcjs');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  return null;
};


export default MailchimpScript;
