import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="about-main-wrappper pt-110">
        <div className="container">
          <div className="about-tab-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="about-tab-image-grid text-center">
                  <div className="about-video d-inline-block">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-g2.png"} alt="aboutIMG" />
                    <div className="video-overlay">
                      <div className="play-icon video-popup">
                        <i
                          onClick={() => setOpen(true)}
                          className="bi bi-play-fill"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row float-images g-4">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-g1.png"} alt="aboutIMG" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-g3.png"} alt="aboutIMG" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="about-tab-wrap">
                  <h2 className="about-wrap-title">
                    Sobre nós <span>Operadora Ponto Com</span> 
                  </h2>
                  <div className="about-tab-switcher">
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                        >
                          <h3>20+</h3>
                          <h6>Anos de Experiência</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                        >
                          <h3>15000+</h3>
                          <h6>Passageiros</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                        >
                          <h3>5000+</h3>
                          <h6>Pacotes vendidos</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                    >
                      <p>
                        Fundada em setembro de 2005, somos uma empresa jovem que rapidamente conquistou seu espaço no mercado. Nossa dedicação, união, honestidade e profissionalismo diários são os pilares que impulsionaram nosso crescimento virtuoso.
                        Nosso compromisso com a excelência e a inovação nos tornou referência na operação de Pacotes Internacionais. Buscamos não apenas satisfazer, mas superar as expectativas de nossos clientes, oferecendo experiências únicas e diferentes do tradicional.
                        Acreditamos que o feedback autêntico dos agentes de viagens e dos passageiros que atendemos seja o melhor testemunho de nossa qualidade e compromisso. É essa confiança mútua que nos impulsiona a continuar aprimorando nossos serviços e expandindo nossos horizontes.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                    >
                      <p>
                      Desde a nossa fundação em setembro de 2005, nossa jornada tem sido marcada por histórias de sucesso e satisfação. Através da dedicação, união, honestidade e profissionalismo de nossa equipe, atingimos um marco impressionante de mais de 15.000 passageiros atendidos. 
                      Este número reflete não apenas a quantidade, mas a qualidade dos serviços que prestamos. Nos esforçamos para ir além das expectativas, criando experiências de viagem inesquecíveis e personalizadas. A confiança e o feedback autêntico de cada passageiro são a verdadeira medida
                       de nosso sucesso e o impulso para continuarmos a inovar e aprimorar nossos pacotes internacionais. A cada viagem concluída, reafirmamos nosso compromisso com a excelência, fortalecendo a relação de confiança mútua com nossos clientes.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                    >
                      <p>
                      Nossa trajetória é pontuada por um crescimento constante e significativo, um reflexo direto da qualidade e inovação que imprimimos em cada pacote que oferecemos. Ultrapassamos a marca de mais de 5.000 pacotes vendidos, um testemunho do nosso compromisso 
                      em oferecer não apenas viagens, mas experiências únicas e memoráveis. Cada pacote é uma promessa de superação das expectativas tradicionais de viagem. Esta conquista é mais do que um número; é uma evidência de nossa dedicação. À medida que continuamos a 
                      expandir nossos horizontes, mantemos nosso foco na excelência e na satisfação do cliente, reforçando nossa posição como referência no mercado de viagens internacionais.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-110">
            <div className="col-lg-6">
              <div className="achievement-counter-wrap">
                <h2 className="about-wrap-title">
                  Por que comprar o seu pacote com a 
                  <span> Operadora Ponto Com?</span>
                </h2>
                <div className="achievement-counter-cards">
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                    <div className="counter-box mb-sm-0 mb-3">
                      <h2>Experiência e Confiabilidade</h2>
                      <h6>Experiência que Gera Confiança</h6>
                    </div>
                    <p>
                      Desde o nosso começo, construímos uma reputação sólida no mercado de viagens internacionais. Nossa experiência não é apenas medida pelo tempo, 
                      mas pela profundidade de conhecimento e pela capacidade de oferecer soluções adaptadas às necessidades e desejos dos nossos clientes. A confiabilidade é o nosso alicerce, 
                      sustentada por anos de entrega consistente de serviços de alta qualidade, honestidade nas interações e um compromisso inabalável com a satisfação do cliente. 
                      Ao escolher viajar conosco, você se beneficia de uma equipe que entende profundamente o setor de viagens e se dedica a fazer da sua experiência algo verdadeiramente único e 
                      memorável.
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div className="counter-box">
                      <h2>Inovação e Experiências Únicas</h2>
                      <h6>A Criação de Viagens Inesquecíveis</h6>
                    </div>
                    <p>
                      Nosso compromisso com a inovação nos coloca na vanguarda do mercado de viagens internacionais. Não nos contentamos em seguir as tendências. Ao comprar conosco, 
                      você tem acesso a pacotes exclusivos, criados com um olhar atento às novas possibilidades e às necessidades em constante evolução dos viajantes. Nossa abordagem 
                      personalizada significa que cada viagem é planejada para oferecer experiências únicas, superando as expectativas tradicionais e proporcionando momentos que se tornam preciosas memórias de vida. 
                      A sua viagem é mais do que um roteiro; é uma jornada desenhada especialmente para você.
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div className="counter-box">
                      <h2>Compromisso com a Qualidade e Satisfação</h2>
                      <h6>O Coração do Nosso Serviço</h6>
                    </div>
                    <p>
                      A razão fundamental para escolher nossa empresa é nosso incansável compromisso com a qualidade e a completa satisfação dos nossos clientes. Cada membro da nossa equipe, cada processo interno, 
                      cada parceria estabelecida tem um único objetivo: assegurar que sua experiência de viagem seja impecável do início ao fim. Valorizamos profundamente o feedback dos nossos clientes. 
                      Ao optar por nossos serviços, você se beneficia de uma cultura empresarial que coloca a satisfação do cliente acima de tudo, garantindo que cada viagem seja uma experiência positiva, enriquecedora e, acima de tudo, inesquecível.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image-group mt-5 mt-lg-0">
                <img src={process.env.PUBLIC_URL + "/images/about/about-image-group2.png"} alt="aboutIMG" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="lSruv4RwPlQ"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutWrapperArea;
