import React from "react";
import AboutWrapperArea from "./AboutWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";

function About() {
  return (
    <>
      <Breadcrumb name="Sobre Nós" type={"static"}/>
      <AboutWrapperArea />
    </>
  );
}

export default About;
