import React, { useEffect, useReducer } from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import config from "../../config";
import { encryptData } from "../../utils/secureEncoder";
import { getExchangeRates } from "../../services/cotationService";

function HomeHeader() {
  const [userDropdown, setuserDropdown] = useState(0);
  const [mobileSideberMenu, setMobileSideberMenu] = useState(0);
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [rates, setRates] = useState(null);
  const [error, setError] = useState(null);

  

  useEffect(() => {
    const subscription = getExchangeRates().subscribe({
        next: (data) => setRates(data),
        error: (err) => setError(err),
    });

    return () => subscription.unsubscribe();
}, []);

  function reducer(state, action) {
    switch (action.type) {
      case "home": {
        const newState = { ...state, activeMenu: "home" };
        return newState;
      }
      case "about": {
        const newState = { ...state, activeMenu: "about" };
        return newState;
      }
      case "package": {
        const newState = { ...state, activeMenu: "package" };
        return newState;
      }
      case "blog": {
        const newState = { ...state, activeMenu: "blog" };
        return newState;
      }
      case "destination": {
        const newState = { ...state, activeMenu: "destination" };
        return newState;
      }
      default:
        return { activeMenu: " " };
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleUserDropDown = () => {
    if (userDropdown === false || userDropdown === 0) {
      setuserDropdown(1);
    } else {
      setuserDropdown(false);
    }
  };
  const handleMobileSiderbar = () => {
    if (mobileSideberMenu === false || mobileSideberMenu === 0) {
      setMobileSideberMenu(1);
    } else {
      setMobileSideberMenu(false);
    }
  };

  const handleClick = (event) => {
        event.preventDefault();
  };

  const encodedGruposeBloqueiosId = encryptData(1);
  const encodedSaidasRegularesId = encryptData(2);

  if (!rates) {
    return <div></div>;
  }
  
  return (
    <>
      <header>
        <div className="header-area header-style-three position-absolute w-100">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                <div className="nav-logo d-flex justify-content-between align-items-center">
                  <a href="/">
                    <img
                      src={config.assetsUrl + "logo-operadora.com.png"}
                      alt="logo"
                      style={{ maxWidth: 180 }}
                    />
                  </a>
                  <div className="mobile-menu d-flex ">
                    <div className="d-flex align-items-center">
                    <button
          className={
            mobileSideberMenu === 1
              ? "hamburger d-block d-xl-none h-active"
              : "hamburger d-block d-xl-none"
          }
          onClick={(e) => {
            e.preventDefault(); // Garante que não redireciona
            handleMobileSiderbar();
          }}
          type="button"
        >
          <span className="h-top" />
          <span className="h-middle" />
          <span className="h-bottom" />
        </button>

                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                <span class="navbar-brand">
                  <div className="mobile-visible" style={{color: '#FFF', fontSize: '16px', marginLeft: '5%', float: 'left', width: '300px'}}>
                    <div style={{float: 'left', width: '150px'}}>
                      Dólar: R$ {rates.USD.bid} 
                    </div>
                    <div style={{float: 'left', width: '150px'}}>
                      Euro: R$ {rates.EUR.bid}
                    </div>
                  </div>
                </span>
              </div>
              </div>
              <div className="col-xxl-4 col-xl-8 col-lg-9 col-md-8 col-sm-6 col-xs-6">
                <nav
                  className={
                    mobileSideberMenu === 1 ? "main-nav slidenav" : "main-nav"
                  }
                >
                  <div className="inner-logo d-xl-none">
                    <Link to={"/"}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                        alt=""
                      />
                    </Link>
                  </div>
                  <ul>
                    <li onClick={scrollTop}>
                      <Link to={"/"}
                      >Home</Link>
                    </li>
                    <li onClick={scrollTop}>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/about`}
                      >
                        Sobre nós
                      </NavLink>
                    </li>
                    <li
                      className="has-child-menu"
                      onClick={() => dispatch({ type: "package" })}
                    >
                      <Link to={"#"} onClick={handleClick}>Pacotes</Link>
                      <i className="fl flaticon-plus">+</i>
                      <ul
                        className={
                          state.activeMenu === "package"
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/package/${encodedGruposeBloqueiosId}?PageSize=12&PageNumber=1`}
                            onClick={scrollTop}
                          >
                            Grupos e Bloqueios
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/package/${encodedSaidasRegularesId}?PageSize=12&PageNumber=1`}
                            onClick={scrollTop}
                          >
                            Saídas Regulares
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/contact`}
                        onClick={scrollTop}
                      >
                        Contato
                      </NavLink>
                    </li>
                  </ul>
                  <div className="inner-contact-options d-xl-none">
                    <div className="contact-box-inner">
                      <i className="bi bi-telephone-fill" />{" "}
                      <a href="tel:+5551999738501">+55(51) 99973-8501</a>
                    </div>
                    <div className="contact-box-inner">
                      <i className="bi bi-envelope-fill" />{" "}
                      <a href="mailto:cida@operadora.tur.br">cida@operadora.tur.br</a>
                    </div>
                    <div className="contact-box-inner">
                      <i className="bi bi-telephone-fill" />{" "}
                      <a href="tel:+555191136163">+55(51) 91136163</a>
                    </div>
                    <div className="contact-box-inner">
                      <i className="bi bi-envelope-fill" />{" "}
                      <a href="mailto:nadia@operadora.tur.br">nadia@operadora.tur.br</a>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-xFxl-1 col-xl-2 col-lg-1">
                <div className="nav-right d-xl-flex d-none">
                  <div className="nav-right-hotline d-xxl-flex d-none">
                    <div className="hotline-info">
                      <h6>
                        Dólar: R$ {rates.USD.bid}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xFxl-1 col-xl-2 col-lg-1">
                <div className="nav-right d-xl-flex d-none">
                  <div className="nav-right-hotline d-xxl-flex d-none">
                    <div className="hotline-info">
                      <h6>
                        Euro: R$ {rates.EUR.bid}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xFxl-3 col-xl-2 col-lg-1">
                <div className="nav-right d-xl-flex d-none">
                  <div className="nav-right-hotline d-xxl-flex d-none">
                    <div className="hotline-info">
                      <h6>
                      <a href="https://wa.me/5551999738501" target="_blank">
                        <i className="bi bi-whatsapp"></i>&nbsp;&nbsp;&nbsp;(51)99973-8501</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HomeHeader;
