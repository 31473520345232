import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import config from "../../../config";
import formatDate from '../../../helpers/formatDate';

// Registrar a fonte
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Definindo os estilos para o PDF
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
    textAlign: 'justify',
  },
  subtitleCover: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
    textAlign: 'center',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  smallText: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Times-Roman',
  },
  smallTextCover: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
  },
  italicText: {
    fontStyle: 'italic',
    margin: 12,
    fontSize: 14,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    marginBottom: 15,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 15,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableCell: {
    width: '33%',
    padding: 5,
    fontSize: 12,
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
  },
});

const convertHTMLToTextWithFormatting = (html) => {
  // Verifica se o HTML é uma string válida e não está vazio
  if (typeof html !== 'string' || !html.trim()) {
    return ''; // Retorna uma string vazia se a entrada não for válida
  }

  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Extraindo o texto e substituindo as tags por quebras de linha conforme necessário
  const filteredText = tempElement.innerHTML
    .replace(/<div[^>]*>/gi, '') // Remove a tag <div>
    .replace(/<\/div>/gi, '\n') // Substitui </div> por uma nova linha
    .replace(/<p[^>]*>/gi, '') // Remove a tag <p>
    .replace(/<\/p>/gi, '\n') // Substitui </p> por uma nova linha
    .replace(/<br\s*\/?>/gi, '\n') // Substitui <br> por uma nova linha
    .replace(/<li[^>]*>/gi, '- ') // Substitui <li> por uma lista com '-'
    .replace(/<\/li>/gi, '\n') // Substitui </li> por uma nova linha
    .replace(/<\/?ul>/gi, '') // Remove <ul> e </ul>
    .replace(/<\/?ol>/gi, '') // Remove <ol> e </ol>
    .replace(/<\/?strong>/gi, '') // Remove <strong> e </strong>
    .replace(/<\/?em>/gi, '') // Remove <em> e </em>
    .replace(/&nbsp;/g, ' ') // Substitui &nbsp; por espaço
    .replace(/<h3[^>]*>(.*?)<\/h3>/gi, '$1\n') // Substitui <h3> por uma nova linha
    .replace(/<a[^>]*>(.*?)<\/a>/gi, '$1') // Remove <a> e mantém o texto dentro

  return filteredText.trim(); // Remove espaços em branco extras no início e no final
};

const Watermark = () => (
  <View style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', opacity: 0.1 }}>
    <Image style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={config.assetsUrl + "logo-operadora.png"} />
  </View>
);

const renderPages = (data) => {
  const pages = [];
  const PAGE_HEIGHT = 842;

  let currentPageContent = [];
  let currentHeight = 0;

  const addContentToPage = (content, height) => {
    currentPageContent.push(content);
    currentHeight += height;

    if (currentHeight >= PAGE_HEIGHT) {
      pages.push(currentPageContent);
      currentPageContent = [];
      currentHeight = 0;
    }
  };

  // Adiciona conteúdo principal
  const headerHeight = 20; // Estimativa de altura do cabeçalho
  addContentToPage(<Text style={styles.header}>Operadora Ponto Com</Text>, headerHeight);
  addContentToPage(<Text style={styles.title}>{data.title}</Text>, headerHeight);
  addContentToPage(<Text style={styles.subtitleCover}>{data.coverTitle}</Text>, headerHeight);
  addContentToPage(<Image style={{ width: '100%' }} src={`${config.pacoteUrl}${data.topBanner}`} />, 200); // Altura da imagem estimada
  addContentToPage(<Text style={styles.subtitle}>Descrição</Text>, headerHeight);
  addContentToPage(<Text style={styles.text}>{convertHTMLToTextWithFormatting(data.description)}</Text>, 100); // Altura do texto estimada

  // Roteiro de Viagem
  addContentToPage(<Text style={styles.subtitle}>Roteiro de Viagem</Text>, headerHeight);
  data.travelPlanDays.forEach(day => {
    addContentToPage(<Text style={styles.subtitle}>Dia {day.dayNumber}: {day.dayDescription}</Text>, headerHeight);
    addContentToPage(<Text style={styles.text}>{convertHTMLToTextWithFormatting(day.description)}</Text>, 100); // Altura do texto estimada
  });

  // O que está incluso
  addContentToPage(<Text style={styles.subtitle}>O que está incluso?</Text>, headerHeight);
  addContentToPage(<Text style={styles.text}>{convertHTMLToTextWithFormatting(data.includedIn)}</Text>, 100); // Altura do texto estimada
  
  // Informações adicionais
  addContentToPage(<Text style={[styles.italicText, styles.smallText]}>{convertHTMLToTextWithFormatting(data.additionalInformations)}</Text>, 100); // Altura do texto estimada

  // Acomodações
  addContentToPage(<Text style={styles.subtitle}>Acomodações</Text>, headerHeight);
  addContentToPage(
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.tableHeader]}>Destino</Text>
        <Text style={[styles.tableCell, styles.tableHeader]}>Single</Text>
        <Text style={[styles.tableCell, styles.tableHeader]}>Double</Text>
        <Text style={[styles.tableCell, styles.tableHeader]}>Triple</Text>
        <Text style={[styles.tableCell, styles.tableHeader]}>Período</Text>
      </View>
      {data.accommodationsDetails.map((accommodation, index) => {
        const rowStyle = index % 2 === 0 ? styles.evenRow : styles.oddRow;
        return (
          <View key={index} style={[styles.tableRow, rowStyle]}>
            <Text style={styles.tableCell}>{accommodation.destination}</Text>
            <Text style={styles.tableCell}>{accommodation.priceToSingle > 0 ? `${data.currency} ${accommodation.priceToSingle}` : "Consultar"}</Text>
            <Text style={styles.tableCell}>{accommodation.priceToDouble > 0 ? `${data.currency} ${accommodation.priceToDouble}` : "Consultar"}</Text>
            <Text style={styles.tableCell}>{accommodation.priceToTriple > 0 ? `${data.currency} ${accommodation.priceToTriple}` : "Consultar"}</Text>
            <Text style={styles.tableCell}>{accommodation.period}</Text>
          </View>
        );
      })}
    </View>,
    200
  );

  if (currentPageContent.length > 0) {
    pages.push(currentPageContent);
  }

  return pages;
};

const MyDocument = ({ data }) => {
  const pages = renderPages(data);

  return (
    <Document>
      {pages.map((pageContent, index) => {
        return (
          <Page style={styles.body} key={index}>
            <Watermark />
            
            {pageContent.map((content, idx) => (
              <View key={idx}>{content}</View>
            ))}
          </Page>
        );
      })}
    </Document>
  );
};

const PackagePDF = ({ data }) => {
  if (!data) {
    return <div>Dados do pacote não disponíveis.</div>;
  }

  return (
    <div>
      <PDFViewer width="100%" height="600px">
        <MyDocument data={data} />
      </PDFViewer>
    </div>
  );
};

export default PackagePDF;
