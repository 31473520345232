import React from "react";
import config from "../../../config";

function PackageHeader({ data }) {
  if (data === null || data === undefined) {
    <></>
  }
  return (
    <>
      <div className="pd-header">
        <div className=" pd-top row row-cols-lg-4 row-cols-md-2 row-cols-2 gy-4">
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd1.svg"} alt="PackageIMG" />
              </div>
              <div className="info">
                <h6>Período</h6>
                <span>{data.periodo}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd2.svg"} alt="PackageIMG" />
              </div>
              <div className="info">
                <h6>Tipo de tour</h6>
                <span>{ data.type == 1 ? "Grupos e Bloqueios" : "Saídas Regulares"}</span>
              </div>
            </div>
          </div>
          {/* <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd3.svg"} alt="PackageIMG" />
              </div>
              <div className="info">
                <h6>Grupo</h6>
                <span>15 Pessoas</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd4.svg"} alt="PackageIMG" />
              </div>
              <div className="info">
                <h6>Tour Guide</h6>
                <span>05 People</span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="pd-thumb">
          <img src={config.pacoteUrl + data.coverImage} alt="PackageIMG" />
        </div>
        <div className="header-bottom">
          <div className="pd-lavel d-flex justify-content-between align-items-center flex-wrap gap-2">
            <h5 className="location">
              <i className="bi bi-geo-alt" /> {data.title}
            </h5>
            <ul className="d-flex align-items-center rating">
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
            </ul>
          </div>
          <h2 className="pd-title">{data.coverTitle}</h2>
        </div>
      </div>
    </>
  );
}

export default PackageHeader;
