import { NavLink } from "react-router-dom";
import React from "react";
import config from "../../config";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useErrorContext } from "../../error";

function Breadcrumb({ dataStore, name, type, loading }) {
  const error = useErrorContext();
  
  if (type === "static") {
    return (
      <div className="breadcrumb breadcrumb-style-one">
        <div className="container">
          <div className="col-lg-12 text-center">
            <h2 className="breadcrumb-title">{name}</h2>
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item">
                <NavLink to={"/"}>Home</NavLink>
              </li>
              <li className="breadcrumb-item active">{name}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  
  if (type === "Generic") {
    return (
      <div className="breadcrumb breadcrumb-style-one">
        <div className="container">
          <div className="col-lg-12 text-center">
            {loading ? (
              <Skeleton height={40} width={200} style={{ marginBottom: '10px' }} />
            ) : (
              <h2 className="breadcrumb-title">{name}</h2>
            )}
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item">
                <NavLink to={"/"}>Home</NavLink>
              </li>
              <li className="breadcrumb-item active">
                {loading ? <Skeleton height={20} width={100} /> : name}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  const backgroundImageUrl = dataStore ? `${config.pacoteUrl + dataStore.data.topBanner}` : '';

  if (loading || !dataStore) {
    if(error){
      return <></>;
    }

    return (
      <div 
        className="breadcrumb breadcrumb-style-one" 
        style={{ 
          backgroundImage: 'none', // Sem imagem de fundo
          position: 'relative',
          height: '200px', // Define uma altura para a div
        }}
      >
        <Skeleton height={200} style={{ width: '100%', position: 'absolute', top: 0, left: 0 }} /> {/* Skeleton que cobre toda a área */}
        <div className="container">
          <div className="col-lg-12 text-center">
            <ul className="d-flex justify-content-center breadcrumb-items">
              
            </ul>
          </div>
        </div>
      </div>
    );
  }

  // Retorno quando os dados estão prontos
  return (
    <div 
      className="breadcrumb breadcrumb-style-one" 
      style={{ 
        backgroundImage: `url(${backgroundImageUrl})`, 
        position: 'relative',
        height: '390px',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="container">
        <div className="col-lg-12 text-center">
          <h2 className="breadcrumb-title">{dataStore.data.title}</h2>
          <ul className="d-flex justify-content-center breadcrumb-items">
            <li className="breadcrumb-item">
              <NavLink to={"/"}>Home</NavLink>
            </li>
            <li className="breadcrumb-item active">{dataStore.data.coverTitle}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
