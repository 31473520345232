import axios from 'axios'
import { Observable } from 'rxjs';
import config from '../config';


const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

function fetchWithRetry(url, retries = MAX_RETRIES) {
    return axios.get(url).catch((error) => {
      if (
        retries > 0 && 
        error.response && 
        (error.response.status === 502 || error.response.status === 500)
      ) {
        return new Promise((resolve) => setTimeout(resolve, RETRY_DELAY)).then(() =>
          fetchWithRetry(url, retries - 1)
        );
      }
      throw error;
    });
  }

export function getAllContinentsWithDestinations(){
    return new Observable((subscriber) => {
      fetchWithRetry(`${config.apiUrl}/Continent/GetAllWithDestinations`)
          .then((response) => {
            if (response.status === 200) {
              subscriber.next(response.data);
              subscriber.complete();
            } else if (response.status === 404) {
              subscriber.error({ redirectTo: '/error' });
            }
          })
          .catch((error) => {
            subscriber.error(error);
          });
      });
}