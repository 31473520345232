import React from 'react';
import config from '../../../config';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function TopDestination({ dataStore, loading }) {
  if (dataStore === null || dataStore === undefined) {
    return <></>;
  }

  const { message, data } = dataStore;

  return (
    <>
      <div className="destination-area destination-style-two pt-110 pb-110">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-sm-10">
              <div className="section-head-alpha text-center mx-auto">
                {loading ? (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={20} style={{ marginTop: '10px' }} />
                  </>
                ) : (
                  <>
                    <h2>Pacotes por Região</h2>
                    <p>Encontre nossos pacotes por região aqui.</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {loading ? (
              // Exibe Skeleton para cada item de destino
              Array.from({ length: 6 }).map((_, index) => (
                <div className={`col-lg-${index === 0 ? 6 : 3} col-md-${index === 0 ? 12 : 6} col-sm-10 fadeffect`} key={index}>
                  <Skeleton height={200} /> {/* Altura do esqueleto do item de destino */}
                  <Skeleton height={20} style={{ marginTop: '10px' }} /> {/* Título */}
                </div>
              ))
            ) : (
              <>
                <div className="col-lg-6 col-md-12 col-sm-10 fadeffect">
                  <a href={`/package/search?Continent=${data[0].id}&PageSize=12&PageNumber=1`}>
                    <DestinationItem image="/images/destination/london.jpg" title={data[0].name} />
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                  <a href={`/package/search?Continent=${data[1].id}&PageSize=12&PageNumber=1`}>
                    <DestinationItem image="/images/destination/caribbean-beach.png" title={data[1].name} />
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                  <a href={`/package/search?Continent=${data[2].id}&PageSize=12&PageNumber=1`}>
                    <DestinationItem image="/images/destination/machu-picchu.jpg" title={data[2].name} />
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                  <a href={`/package/search?Continent=${data[3].id}&PageSize=12&PageNumber=1`}>
                    <DestinationItem image="/images/destination/mill-valley-ca.png" title={data[3].name} />
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                  <a href={`/package/search?Continent=${data[4].id}&PageSize=12&PageNumber=1`}>
                    <DestinationItem image="/images/destination/south-africa-elephants.jpg" title={data[4].name} />
                  </a>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-10 fadeffect">
                  <a href={`/package/search?Continent=${data[5].id}&PageSize=12&PageNumber=1`}>
                    <DestinationItem image="/images/destination/taj-mahal.jpg" title={data[5].name} />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function DestinationItem(props) {
  return (
    <>
      <div className="destination-item">
        <div className="destination-img">
          <img src={`${process.env.PUBLIC_URL}${props.image}`} alt="destination img" style={{ maxHeight: 360 }} />
        </div>
        <div className="destination-overlay">
          <div className="content">
            <a href="destination-details.html"><h5>{props.title}</h5></a>
            <a href="destination-details.html"><h6>{props.place}</h6></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopDestination;
