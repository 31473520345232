import React from "react";
import PackageHeader from "./PackageHeader";
import PackageDetailsTab from "./PackageDetailsTab";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet';
import config from "../../../config";
import { encryptData } from "../../../utils/secureEncoder";
import { useErrorContext } from '../../../error';

function PackageDetailsWrap({ dataStore, loading }) {
  const { error } = useErrorContext();

  if (loading || !dataStore) {
    if(error){
      return <></>;
    }
    
    return (
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <Skeleton height={500} style={{ marginBottom: '20px' }} />
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="tour-package-details">
                <Skeleton height={300} style={{ marginBottom: '20px' }} />
                <Skeleton count={3} height={20} style={{ marginBottom: '10px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { message, data } = dataStore;

  const defaultImage = `https://www.operadora.tur.br/assets/frontend/images/logo-operadora.png`;

  return (
    <>
      <Helmet>
        <title>{data ? `${data.title} - Detalhes do Pacote` : 'Carregando...'}</title>
        <meta property="og:title" content={data ? data.title : 'Operadora Ponto Com - Pacote de viagem'} />
        <meta property="og:description" content={data ? data.description : 'Detalhes do pacote.'} />
        <meta property="og:image" content={data && data.coverImage ? `${config.pacoteUrl}${data.coverImage}` : defaultImage} />
        <meta property="og:url" content={data ? `${process.env.PUBLIC_URL}/package-details/${encryptData(data.id)}` : '#'} />
        <meta property="og:type" content="website" />
      </Helmet>
    
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="tour-package-details">
                <PackageHeader data={data} />
                <PackageDetailsTab data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageDetailsWrap;
